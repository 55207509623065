import "core-js/modules/es.array.push.js";
import axios from 'axios';
export default {
  name: 'HomeView',
  components: {},
  created() {
    document.title = '在线编辑';
    this.clientWidth = document.documentElement.clientWidth;
    this.height = window.screen.height;
    this.width = window.screen.width;
    console.log('高度1', this.clientWidth, this.height);
    const userAgent = navigator.userAgent;
    console.log(userAgent, 'userAgent', userAgent.indexOf('Alipay'));
    if (userAgent.indexOf('Alipay') > -1) {
      // 支付宝小程序的 JS-SDK 防止 404 需要动态加载，如果不需要兼容支付宝小程序，则无需引用此 JS 文件。
      document.writeln('<script src="https://appx/web-view.min.js"' + '>' + '<' + '/' + 'script>');
    }
  },
  data() {
    return {
      timer: null,
      current: 0,
      clientWidth: '',
      content: '',
      height: '',
      isNext: true,
      width: '',
      list: [],
      token: '',
      lid: '',
      type: 0,
      contractCode: '',
      userid: '',
      realname: '',
      isLink: false,
      libraryId: '',
      jsonData: '',
      templateOtherSide: {},
      rcContracts: [],
      users: [],
      applicable: 0
    };
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.clientWidth = document.documentElement.clientWidth;
    });
    if (this.$route.query.lid) {
      this.lid = this.$route.query.lid;
    } else if (this.$route.query.libraryId) {
      this.libraryId = this.$route.query.libraryId;
      this.applicable = this.$route.query.applicable;
    }
    this.token = this.$route.query.token;
    console.log(this.$route, 'route=====================');
    this.login();
  },
  methods: {
    checkActive(index) {
      return index === this.current; // current为监听的轮播index
    },

    onChange(index) {
      this.current = index;
    },
    nextStep() {
      let isreq = 0;
      const eles = document.getElementById('fillContent').getElementsByTagName('input');
      for (var i = 0; i < eles.length; i++) {
        var value1 = eles[i].value;
        var lengt = eles[i].getAttribute('length');
        if (eles[i].getAttribute('required') != null && value1.trim() === '') {
          eles[i].style.borderBottom = '1px solid red';
          isreq = 1;
        } else {
          eles[i].style.borderBottom = '1px solid black';
        }
      }
      if (isreq == 1) {} else {
        for (var i = 0; i < eles.length; i++) {
          var value1 = eles[i].value;
          var lengt = eles[i].getAttribute('length');
          var value1 = value1.padEnd(parseInt(lengt), ' ');
          const newText = document.createElement('span');
          newText.innerHTML = value1;
          if (eles[i].parentNode.parentNode.nodeName == 'TD') {
            newText.style.textAlign = 'center';
          } else {
            newText.style.textDecoration = 'underline';
          }
          newText.style.whiteSpace = 'pre-wrap';
          eles[i].parentNode.replaceChild(newText, eles[i]);
          i = i - 1;
        }
        const content = document.getElementById('fillContent').innerHTML;
        this.getContentHtml(content);
        // this.onLink()
      }
    },

    backWindow() {
      dd.postMessage({
        name: '测试web-view'
      });
    },
    onLink() {
      // this.$router.push('/detail?lid=' + this.lid)
      if (this.$route.query.libraryId) {
        this.$router.push({
          name: 'detail',
          params: {
            lid: this.lid,
            jsonData: this.jsonData
          }
        });
      } else {
        this.$router.push({
          name: 'detail',
          params: {
            lid: this.lid
          }
        });
      }
    },
    getContentHtml(content) {
      this.$store.commit('getContentHtml', content);
    },
    login() {
      const that = this;
      localStorage.setItem('token', this.$route.query.token);
      localStorage.setItem('userid', this.$route.query.userid);
      localStorage.setItem('realname', this.$route.query.realname);
      localStorage.setItem('department', this.$route.query.department);
      localStorage.setItem('departmentId', this.$route.query.departmentId);
      this.token = localStorage.getItem('token');
      this.userid = localStorage.getItem('userid');
      this.realname = localStorage.getItem('realname');
      this.$nextTick(() => {
        if (localStorage.getItem('token')) {
          that.getCode();
        }
      });
      // this.request.post('/sys/login', { password: '.', username: 'admin' }).then((res) => {
      //   const {
      //     code,
      //     result
      //   } = res
      //   localStorage.setItem('token', result.token)
      //   if (code == 200) {

      //     that.getTemplateList()

      //   }
      // })
    },

    getTemplateList() {
      const that = this;
      const content = {
        detailContent: {},
        contractCode: ''
      };
      this.request.get('/rcTemplateLibrary/rcTemplateLibrary/list?applicable=' + this.applicable).then(res => {
        const {
          code,
          result
        } = res;
        if (code == 200) {
          that.list = result;
          if (that.$route.query.lid) {
            const index = Number(that.$route.query.lid) || 0;
            // console.log(that.$route.query.lid, 'id');
            that.getContentDetail(that.list[index].id);
            content.detailContent = that.list[index];
            content.contractCode = that.contractCode;
            that.$nextTick(() => {
              that.getContractObject(content);
            });
            console.log(that.list[index].id, 'that.list[index].id ');
            this.onLink();
            // if (that.list[index].id === '1' || that.list[index].id === '2' || that.list[index].id === '3') {
            //   this.isLink = true
            //   this.onLink()
            // }
          } else if (that.$route.query.libraryId) {
            const libraryId = that.$route.query.libraryId;
            console.log(that.$route.query.libraryId, 'that.$route.query.libraryId');
            that.list.forEach((item, index) => {
              if (item.id == that.$route.query.libraryId) {
                that.lid = index;
                that.getDraftDetail(that.$route.query.id);
                // console.log(index,item,'libraryId-------',that.$route.query.id);
              }
            });
          }
        }
      });
    },

    // 草稿箱详情
    getDraftDetail(id) {
      const that = this;
      // this.getContentDetail(id)
      this.request.get('/rcContractDraft/rcContractDraft/queryById?id=' + id).then(res => {
        const {
          code,
          result
        } = res;
        this.content = result.content;
        const detailContent = {
          id: result.libraryId,
          ids: result.id,
          contractName: result.contractName,
          //
          // association: result.association,//关联合同
          content: result.content,
          //
          contractObject: result.contractObject,
          // 合同对象 id
          contractWith: result.contractWith,
          //
          contractRemark: result.contractRemark,
          contractTag: result.contractTag,
          // 合同标签
          libraryId: result.libraryId,
          // 模板id
          amountType: result.amountType,
          // 金额类型
          amount: result.amount,
          // 金额
          effectTime: result.effectTime,
          //
          // projectApprovalFile: result.projectApprovalFile,//立项文件
          validTime: result.validTime,
          //,
          budgetApplication: result.budgetApplication,
          // 采购预算申请表
          purchasingRequisition: result.purchasingRequisition,
          //  采购申请表
          partySummary: result.partySummary,
          //  党委会纪要
          presidentSummary: result.presidentSummary,
          //  院长办公会纪要
          transactionAnnouncement: result.transactionAnnouncement //  成交公告
        };

        const content = {
          detailContent: detailContent,
          contractCode: this.contractCode
        };
        this.jsonData = JSON.stringify(detailContent);
        if (result.templateOtherSide) {
          this.$store.commit('getOppsiteItem', result.templateOtherSide);
          // this.templateOtherSide = result.templateOtherSide
        }

        if (JSON.parse(result.association)[0]) {
          this.$store.commit('getRelatedContractList', JSON.parse(result.association));
          // this.rcContracts= result.rcContracts
        }

        if (result.users[0]) {
          this.$store.commit('getCoordinatorList', result.users);
        }
        this.$nextTick(() => {
          this.getContractObject(content);
        });
        this.onLink();
        // if (that.libraryId === '1' || that.libraryId === '2' || that.libraryId === '3') {
        //   this.isLink = true
        //   this.onLink()
        // }
      });
    },

    getCode() {
      this.request.get('/rcContract/rcContract/getContractCode').then(res => {
        const {
          code,
          result
        } = res;
        this.contractCode = result.contractCode;
        this.getTemplateList();
        // console.log(res, 'getContractCode222', this.contractCode);
      });
    },

    getContractObject(content) {
      this.$store.commit('getContractObject', content);
      // console.log("成功---", content);
    },

    getContentDetail(id) {
      this.request.get('/rcTemplateContent/rcTemplateContent/queryByLId?type=1&lid=' + id).then(res => {
        const {
          code,
          result
        } = res;
        if (code == 200) {
          this.content = result.content;
          this.getContentHtml(this.content);
        } else {
          this.content = '<h2 style="text-align: center;">' + res.message + ' !' + '</h2>';
          this.$message.error(res.message);
          console.log(res.message, 'res.message');
        }
      });
    }
  }
};